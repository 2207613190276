<script>
export default {
	computed: {
		product() {
			return this.$parent.$parent.product
		},
		showTemplate() {
			return (
				this.product.info?.templateKey == 'descriptionAndAttrs' && (this.description || this.attrs.length)
			)
		},
		description() {
			return this.product.info?.templateData?.description || this.product.info?.description
		},
		distillery() {
			return this.product.info?.templateData?.distillery
		},
		bodega() {
			return this.product.info?.templateData?.bodega
		},
		attrs() {
			return this.product.attrs || []
		},
		both() {
			return this.description && this.attrs.length
		},
	},
}
</script>

<template>
	<portal to="Product.info" v-if="showTemplate">
		<Container class="mt-8 mb-16 pa-8" max-width="90%">
			<v-row class="flex-md-nowrap flex-column flex-md-row">
				<v-col cols="12" :md="both ? 5 : 12" v-if="attrs.length" order="1" order-md="3">
					<div class="font-2 font-weight-bold pb-4 font-header">CARACTERÍSTICAS</div>
					<v-row>
						<v-col cols="6" md="4" v-for="(item, i) of attrs" :key="i">
							<div class="font-weight-bold text-uppercase font-0">
								{{ item.attrKey.k }}
							</div>
							<div class="pt-1">
								{{ item.v }}
							</div>
						</v-col>
					</v-row>
				</v-col>
				<v-divider
					:vertical="$vuetify.breakpoint.mdAndUp"
					class="mx-md-4 my-4 my-md-0"
					v-if="both"
					style="order: 2"
				/>
				<v-col cols="12" :md="both ? 7 : 12" order="3" order-md="1">
					<div v-if="description">
						<div class="font-2 font-weight-bold pb-4 font-header">DESCRIPCIÓN</div>
						<TruncatedText :text="description" :max-chars="$vuetify.breakpoint.xs ? 300 : 0" />
					</div>
					<div v-if="distillery" class="mt-5">
						<div class="font-2 font-weight-bold pb-4 font-header text-uppercase">Destilería</div>
						<TruncatedText :text="distillery" :max-chars="$vuetify.breakpoint.xs ? 300 : 0" />
					</div>
					<div v-if="bodega" class="mt-5">
						<div class="font-2 font-weight-bold pb-4 font-header text-uppercase">Bodega</div>
						<TruncatedText :text="bodega" :max-chars="$vuetify.breakpoint.xs ? 300 : 0" />
					</div>
				</v-col>
			</v-row>
		</Container>
	</portal>
</template>

<style scoped>
.container {
	border-top: 1px solid #ccc;
}
</style>
