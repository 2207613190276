<script>
import mplogo from './assets/mercadopago-logo.webp'
import amex from './assets/logo_amex.svg'
import visa from './assets/logo_visa.svg'
import master from './assets/logo_master.svg'
import pagofacil from './assets/pagofacil.jpg'
import rapipago from './assets/rapipago.jpg'
//import ahora3 from './assets/ahora_3.jpg'
//import ahora6 from './assets/ahora_6.jpg'
//import ahora12 from './assets/ahora_12.png'
//import ahora18 from './assets/ahora_18.png'
//import ahora24 from './assets/ahora_24.png'

export default {
	lang: 'shop',
	data() {
		return {
			mplogo,
			cardsLogos: [visa, master, amex, pagofacil, rapipago],
		}
	},
}
</script>

<template>
	<CheckoutPaymentCardContent :logo-src="mplogo" title="Mercado Pago">
		<v-list>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0"> Hasta 2 cuotas sin interés </v-list-item-content>
			</v-list-item>
		</v-list>
		<!-- <v-list>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Sin recargo adicional' | lang }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					{{ 'Pagá en cuotas sin interés con tarjetas seleccionadas' | lang }}
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<div class="d-flex flex-wrap pt-4">
			<Media
				v-for="imgUrl in cardsLogos"
				:key="imgUrl"
				:src="imgUrl"
				max-width="60"
				max-height="20"
				class="mb-2 mr-2"
				contain
			/>
		</div> -->
	</CheckoutPaymentCardContent>
</template>
