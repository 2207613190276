<script>
import getnetLogo from './getnet-logo.png'

export default {
	data() {
		return {
			getnetLogo,
		}
	},
}
</script>

<template>
	<CheckoutPaymentCardContent :logo-src="getnetLogo" title="Getnet">
		<v-list>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0"> Pagá con Tarjeta de Crédito o Débito </v-list-item-content>
			</v-list-item>
			<v-list-item class="pl-1" dense>
				<v-list-item-icon class="mr-0">
					<v-icon small>mdi-chevron-right</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="pa-0">
					<div>Consultar con el banco posibles beneficios.</div>
					<div><small>Solo para clientes Santander</small></div>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</CheckoutPaymentCardContent>
</template>
